import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";

import { Link, graphql } from "gatsby";

import * as styles from "../styles/frameworks.module.css";

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "frontend" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            category
            title
            imageurl
            description
            date
            author
            imagealt
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
const FrontendPage = props => {
  const [ad, setAd] = useState({});
  useEffect(() => {
    const randomvalue = Math.floor(Math.random() * 1400);
    switch (true) {
      case randomvalue < 210:
        setAd({
          href: "https://www.jdoqocy.com/click-100376026-13608893",
          src: "https://www.lduhtrp.net/image-100376026-13608893",
          alt: "Hostinger",
        });
        break;

      case randomvalue > 210 && randomvalue < 500:
        setAd({
          href: "https://www.dpbolvw.net/click-100376026-14326263",
          src: "https://www.awltovhc.com/image-100376026-14326263",
          alt: "Popular Domains for just 99 Cents at Namecheap!",
        });
        break;

      case randomvalue > 500 && randomvalue < 700:
        setAd({
          href: "https://www.jdoqocy.com/click-100376026-14483390",
          src: "https://www.tqlkg.com/image-100376026-14483390",
          alt: "",
        });
        break;

      case randomvalue > 700 && randomvalue < 760:
        setAd({
          href: "https://www.jdoqocy.com/click-100376026-13868749",
          src: "https://www.ftjcfx.com/image-100376026-13868749",
          alt: "",
        });
        break;

      case randomvalue > 760 && randomvalue < 800:
        setAd({
          href: "https://www.anrdoezrs.net/click-100376026-11751862",
          src: "https://www.awltovhc.com/image-100376026-11751862",
          alt: "Get up to 30% off all new products with GoDaddy!",
        });
        break;

      case randomvalue > 800 && randomvalue < 900:
        setAd({
          href: "https://www.kqzyfj.com/click-100376026-10833186",
          src: "https://www.awltovhc.com/image-100376026-10833186",
          alt: "Get Hosting for $1.00*/mo with GoDaddy",
        });
        break;

      case randomvalue > 900 && randomvalue < 910:
        setAd({
          href: "https://www.kqzyfj.com/click-100376026-1452679",
          src: "https://www.lduhtrp.net/image-100376026-14526799",
          alt: "",
        });
        break;

      case randomvalue > 910 && randomvalue < 1050:
        setAd({
          href: "https://www.dpbolvw.net/click-100376026-13266669",
          src: "https://www.lduhtrp.net/image-100376026-13266669",
          alt: "Search and buy domains from Namecheap",
        });
        break;

      case randomvalue > 1050 && randomvalue < 1200:
        setAd({
          href: "https://www.anrdoezrs.net/click-100376026-14551171",
          src: "https://www.tqlkg.com/image-100376026-14551171",
          alt: "",
        });
        break;
      case randomvalue > 1200:
        setAd({
          href: "https://www.kqzyfj.com/click-100376026-14526802",
          src: "https://www.ftjcfx.com/image-100376026-14526802",
          alt: "",
        });
        break;

      default:
        setAd({
          href: "https://www.jdoqocy.com/click-100376026-14483390",
          src: "https://www.tqlkg.com/image-100376026-14483390",
          alt: "",
        });
    }
  }, [props]);
  return (
    <Layout>
      <Helmet>
        <title>Webdevolution | Front-end articles</title>
        <meta
          name="description"
          content="Front-end web development blog posts."
        />
      </Helmet>
      <div className={styles.mainContainer}>
        <div className={styles.adContainerWide}>
          {/* <AdComponent href={ad.href} src={ad.src} alt={ad.alt}></AdComponent> */}
        </div>
        {props.data.allMarkdownRemark.edges.map(i => {
          return (
            <Link
              key={i.node.frontmatter.title}
              to={`/blog/${i.node.fields.slug}`}
            >
              <div className={styles.postContainer}>
                <img
                  className={styles.image}
                  src={i.node.frontmatter.imageurl}
                  alt={i.node.frontmatter.imagealt}
                />

                <div className={styles.postText}>
                  <h1 className={styles.posth1}>{i.node.frontmatter.title}</h1>

                  <p className={styles.postp}>
                    {i.node.frontmatter.description}
                  </p>
                  <p className={styles.authorSpan}>
                    {i.node.frontmatter.date} By{" "}
                    <span className={styles.authorSpan}>
                      {i.node.frontmatter.author}
                    </span>
                  </p>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
      <div className={styles.adContainerSmall}>
        <a
          href="https://www.anrdoezrs.net/click-100376026-13346111"
          target="_top"
        >
          <img
            src="https://www.ftjcfx.com/image-100376026-13346111"
            width="300"
            height="250"
            alt="Big Discounts for Domains, Hosting, SSL and more"
            border="0"
          />
        </a>
      </div>
    </Layout>
  );
};

export default FrontendPage;
